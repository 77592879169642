<template>
  <v-container class="col-9">
    <v-alert type="error" v-model="r_fail" dismissible
      >Something went wrong :(</v-alert
    >
    <v-alert type="success" v-model="r_success" dismissible
      >File successfully uploaded!</v-alert
    >
    <v-card>
      <v-card-title>Upload</v-card-title>
      <v-card-subtitle>Upload a new file for analysis</v-card-subtitle>
      <v-card-text>
        <v-form>
          <v-file-input
            label="Choose file"
            outlined
            dense
            prepend-icon="mdi-file-find"
            v-model="file"
            @change="check_file"
          ></v-file-input>
          <v-card outlined v-if="zipfile" class="mb-5">
            <v-card-text
              ><v-checkbox v-model="zip_unpack" label="Unpack ZIP"></v-checkbox>
              <v-text-field
                v-model="zip_password"
                :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                label="ZIP password"
                outlined
                dense
                prepend-icon="mdi-key"
                :type="show_pass ? 'text' : 'password'"
                :disabled="!zip_unpack"
                @click:append="show_pass = !show_pass"
              ></v-text-field
            ></v-card-text>
          </v-card>
          <v-btn color="green" outlined @click="submit"
            ><v-icon left>mdi-upload</v-icon>Submit</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Upload",
  data() {
    return {
      file: null,
      r_success: false,
      r_fail: false,
      zipfile: false,
      zip_unpack: false,
      zip_password: "",
      show_pass: false,
    };
  },
  methods: {
    submit() {
      let formData = new FormData();
      formData.append("file", this.file);
      if (this.zipfile) {
        formData.append("zip_unpack", this.zip_unpack);
        formData.append("zip_password", this.zip_password);
      }
      axios.post(this.$api_base_url + "/submit", formData).then((response) => {
        if (response.data === "success") {
          this.r_success = true;
          this.r_fail = false;
          this.file = null;
          this.zipfile = false;
          this.zip_unpack = false;
          this.zip_password = null;
          this.show_pass = false;
        } else {
          this.r_fail = true;
          this.r_success = false;
        }
      });
    },
    check_file() {
      if (this.file["type"] === "application/x-zip-compressed") {
        this.zipfile = true;
      } else {
        this.zipfile = false;
      }
    },
  },
};
</script>